














import {defineComponent, ref} from "@vue/composition-api";
import {getDatabase, onValue, ref as firebaseRef} from "firebase/database";
import { getAuth, onAuthStateChanged, signInWithEmailAndPassword, browserSessionPersistence } from "firebase/auth";

export default defineComponent({
  name: 'App',
  components: {},
  props: {},
  setup(){
    const user = ref()
    const auth = getAuth()
    onAuthStateChanged(auth, (state)=>{
      if(window.location.pathname === "/login") return
      const currentUser = auth.currentUser
      if(!currentUser) return window.location.href = "/login";
      user.value = currentUser
      localStorage.setItem("uid", currentUser.uid)
    })

    return {
      user
    }
  }
});
