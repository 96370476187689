






import Vue from 'vue';
import HelloWorld from '@/components/HelloWorld.vue';
import {ref} from "@vue/composition-api";
import {child, get, getDatabase, onValue} from "firebase/database";
import {ref as firebaseRef} from "@firebase/database"; // @ is an alias to /src

export default Vue.extend({
  name: 'Home',
  components: {},
  setup(){
    const users = ref([])
    const db = getDatabase();
    const dbRef = firebaseRef(db)
    const usersRef = firebaseRef(db, "users")

    get(child(dbRef, "users")).then((snapshot)=>{
      console.log("val", snapshot.val())
      users.value = snapshot.val()
    })

    return {
      users
    }
  }
});
