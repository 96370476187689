import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import VueCompositionAPI from '@vue/composition-api'
import {initializeApp} from "firebase/app";

Vue.config.productionTip = false
Vue.use(VueCompositionAPI)

const firebaseConfig = {
  apiKey: "AIzaSyACW-yHR99X9oAIgRkx3ZtlrndtUvWCuJE",
  authDomain: "pibell-f9bc9.firebaseapp.com",
  databaseURL: "https://pibell-f9bc9.firebaseio.com",
  projectId: "pibell-f9bc9",
  storageBucket: "pibell-f9bc9.appspot.com",
  messagingSenderId: "103500284205"
};

initializeApp(firebaseConfig);

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')



